/*
angular.module('ui.bootstrap.carousel')
    .controller('CarouselController', [function () { }])
    .directive('carousel', [function () { return {}; }]);

    */

angular.module('ui.bootstrap.carousel', [])
    .controller('CarouselController', ['$scope', '$timeout', '$q', function ($scope, $timeout, $q) {
    }]).directive('carousel', [function () {
        return {
        }
    }]);